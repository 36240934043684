<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('dataArchive.db_backup_title') }}</h4>
      </template>
      <template v-slot:body>
      <b-row>
        <b-col>
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('dataArchive.component')"
              label-for="component_id"
              >
              <b-form-select
                plain
                v-model="component_id"
                id="component_id"
                :options="componentList"
                @change="loadData"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="defaultNull">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button type="button" variant="primary" @click="getDbBackup">{{ $t('dataArchive.db_backup_btn')}}</b-button>
          </b-col>
      </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('dataArchive.backup_list')}}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loader">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="!loader" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(created_date)="data">
                      {{ data.item.created_date | dateFormat }}
                    </template>
                    <template v-slot:cell(file_size)="data">
                     <span class="badge"> {{ $n(data.item.file_size) + $t('dataArchive.file_size_unit') }} </span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a :href="downloadUrl + '?file_name=' + data.item.file_name" target="_blank" title="Download"><i class="fa fa-download"></i></a> &nbsp; &nbsp;
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Remove" @click="remove(data.item)"><i class="fas fa-times"></i></a>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, {
  authServiceBaseUrl,
  commonServiceBaseUrl,
  irriSchemeServiceBaseUrl,
  warehouseServiceBaseUrl,
  agriMarketingServiceBaseUrl,
  seedFertilizerServiceBaseUrl
} from '@/config/api_config'
import { dbBackupApi, dbDownloadApi, dbBackupListApi, dbBackupDeleteApi } from '../../api/routes'

export default {
  data () {
    return {
      loader: false,
      defaultNull: 0,
      errorAlert: false,
      errorMsg: '',
      component_id: 0,
      hasExport: false,
      baseUrl: '',
      downloadUrl: '',
      listData: [],
      baseUrls: [authServiceBaseUrl, commonServiceBaseUrl, irriSchemeServiceBaseUrl, warehouseServiceBaseUrl, agriMarketingServiceBaseUrl, seedFertilizerServiceBaseUrl]
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    componentList () {
      let compList = this.$store.state.commonObjCommonConfig.componentList
      compList = JSON.parse(JSON.stringify(compList))
      compList.unshift({
        value: 99,
        text: this.currentLocale === 'en' ? 'Authentication Service' : 'অথেনটিকেশন সার্ভিস'
      })
       return compList
    },
    columns () {
      return [
        { key: 'index', label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { key: 'created_date', label: this.$t('dataArchive.backup_date') },
        { key: 'file_name', label: this.$t('dataArchive.file_name') },
        { key: 'file_size', label: this.$t('dataArchive.file_size') },
        { key: 'action', label: this.$t('globalTrans.action'), class: 'text-center' }
      ]
    }
  },
  watch: {
  },
  created () {
  },
  mounted () {
  },
  methods: {
    remove (item) {
      window.vm.$swal({
        title: window.vm.$t('dataArchive.delete_confirm'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
    }).then((result) => {
      if (result.isConfirmed) {
       this.removeBackupFile(item)
      }
    })
    },
    getDbBackup () {
      if (this.component_id === 0) {
        this.errorMsg = this.$i18n.locale === 'en' ? 'Please, select a component' : 'কম্পোনেন্ট নির্বাচন করুন'
        this.$swal({
          title: this.$t('globalTrans.error'),
          text: this.errorMsg,
          icon: 'warning'
        })
        return
      }
      this.loader = true
      RestApi.getData(this.baseUrl, dbBackupApi).then(res => {
        if (res.success) {
          this.loadData()
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.$t('dataArchive.success_msg'),
            color: '#D6E09B'
          })
        }
        this.loader = false
      }, err => {
        if (err) {
          //
        }
        this.loader = false
      })
    },
    loadData () {
      if (this.component_id === 1) {
        this.baseUrl = commonServiceBaseUrl
      } else if (this.component_id === 5) {
        this.baseUrl = agriMarketingServiceBaseUrl
      } else if (this.component_id === 6) {
        this.baseUrl = warehouseServiceBaseUrl
      } else if (this.component_id === 8) {
        this.baseUrl = seedFertilizerServiceBaseUrl
      } else if (this.component_id === 9) {
        this.baseUrl = irriSchemeServiceBaseUrl
      } else if (this.component_id === 99) {
        this.baseUrl = authServiceBaseUrl
      } else {
        this.errorMsg = this.$i18n.locale === 'en' ? 'This component is not developed yet.' : 'এই কম্পোনেন্টটি এখনো ডেভেলপড করা হয়নি'
        this.$swal({
          title: this.$t('globalTrans.error'),
          text: this.errorMsg,
          icon: 'warning'
        })
        return
      }
      this.loader = true
      this.downloadUrl = this.baseUrl + dbDownloadApi
      RestApi.getData(this.baseUrl, dbBackupListApi).then(res => {
        if (res.success) {
          this.listData = res.data
        }
        this.loader = false
      })
    },
    removeBackupFile (item) {
      this.loader = true
      RestApi.deleteData(this.baseUrl, dbBackupDeleteApi + `?file_name=${item.file_name}`).then(res => {
        if (res.success) {
          this.loadData()
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.$t('dataArchive.delete_msg'),
            color: '#D6E09B'
          })
        } else {
          window.vm.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
        this.loader = false
      }, err => {
        if (err) {
        }
        this.loader = false
      })
    }
  }
}
</script>
